if (process.env.MODE !== "web") {
  require("dotenv").config({ path: ".env.app.local" });
}

const nodeEnv = process.env.NODE_ENV;
const mode = process.env.MODE;
const appId = process.env.APP_ID || "app-template";
const deviceId = process.env.DEVICE_ID || "app-template";

const cmsHost = process.env.CMS_HOST || "http://localhost:3008";
const dataFetchSlug = process.env.DATA_FETCH_SLUG || deviceId;

const width = parseInt(process.env.WIDTH) || 1920;
const height = parseInt(process.env.HEIGHT) || 1080;
const idleTimeout = parseInt(process.env.IDLE_TIMEOUT) || 0;

const mqttEnabled = process.env.MQTT_ENABLED === "true";
const mesoConnectConfigUrl =
  process.env.MESO_CONNECT_CONFIG_URL || "meso-connect-config.json";
const brokerUsername = process.env.BROKER_USERNAME || "";
const brokerPassword = process.env.BROKER_PASSWORD || "";
const brokerOverride = {
  protocol: process.env.BROKER_PROTOCOL,
  address: process.env.BROKER_ADDRESS,
  port: parseInt(process.env.BROKER_PORT, 10),
};
const mqttSubscribe = {
  firstProp: `${deviceId}/first-node/first-prop`,
};
const mqttPublish = {};

module.exports = {
  nodeEnv,
  mode,
  deviceId,
  appId,
  cmsHost,
  dataFetchSlug,
  width,
  height,
  idleTimeout,
  mqttEnabled,
  mesoConnectConfigUrl,
  brokerUsername,
  brokerPassword,
  brokerOverride,
  mqttSubscribe,
  mqttPublish,
};
