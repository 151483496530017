import { useSelector } from "react-redux";
import LoadingScreen from "./Loading/LoadingScreen";

export default function App() {
  const dataLoadingState = useSelector(
    (state) => state.general.dataLoadingState,
  );

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {dataLoadingState === "pending" ? (
        <LoadingScreen />
      ) : (
        <h1>MESO App Template</h1>
      )}
    </div>
  );
}
